.user_card{
    width: 350px;
    min-height: 180px;
    padding: 15px;
    background-color: #2A2D37;
    border-radius: 10px;
    display: flex;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 20px;
}
.user_photo{
    width: 40%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}
.user_details{
    margin-left: 20px;
}
.user_details p{
    margin-bottom: 10px;
    width: 150px; 
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
}
.user_details img{
    margin-bottom: 10px;
    width: 20px;
}
