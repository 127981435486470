.friendList{
    width: 100%;
    background-color: #262626;
    height: 700px;
    overflow-y: auto;
}
.friend_list ul {
    margin-top: 10px;
}
.friend_list ul li{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%;
    padding: 10px 30px;
}
.friend_list ul li:hover{
    background-color: #333;
}
.friend_list img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
}