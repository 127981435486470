.checkout_card{
    width: 600px;
    background-color: #2A2D37;
    padding: 30px 20px;
}
.checkout_card_holder h3{
    color: orange;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
}
.checkout_card input{
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 10px;
}
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
  }
.checkout_card p{
    color: orange;
    margin-left: 8px;
    margin-bottom: 20px;
}
.checkout_card button{
    width: 100%;
    background: linear-gradient(46deg, #F79220 55.15%, #F94A2B 82%);
    padding: 10px 20px;
    color: #fff;
    margin-top: 20px;
    border-radius: 10px;
}
.input_holder{
    display: flex;
}
.input_holder p{
    margin-bottom: 5px;
    margin-right: 5px;
    width: 30%;
    margin-top: 8px;
}
.input_holder input{
    width: 70%;
}
.accepted_card_icons{
    display: flex;
    width: 250px;
    justify-content: space-between;
    margin-left: auto;
    margin-top: -10px;
    margin-bottom: 10px;
    margin-right: 135px;
}
.checkout_card_holder{
    display: flex;
    border-radius: 10px;
    overflow: hidden;
}
.checkout_billing{
    padding: 30px 20px;
    background-color: #2A2D37;
    border-right: 1px solid #777;
}
.billing_detail_sec{
    width: 300px;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
}
.billing_detail_sec div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.billing_detail_sec div p:first-child{
    font-weight: 600;
}