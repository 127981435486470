.chatroomscreen{
    width: 100%;
    height: 700px;
    background-color: #262626;
    box-shadow: 0 0 20px rgba(0,0,0,0.6);
    position: relative;
}
.str-chat__channel-list-messenger__main {
    background-color: #262626;
}
.str-chat__channel-list-messenger-react__main{
    background-color: #262626;
}
.str-chat{
    --str-chat__avatar-background-color: orange !important;
    --str-chat__own-message-bubble-background-color: orange !important;
}